import React, { ReactElement } from "react";
import style from "../../../styles/buttons.module.scss";

interface Props {
  onClick: () => void;
}

const CloseButton = (props: Props): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width="24"
    height="24"
    className={style.circleButton}
    onClick={props.onClick}
    role="button"
    aria-label="Close"
  >
    <path d="M257 0C116.4 0 0 114.4 0 255s116.4 257 257 257 255-116.4 255-257S397.6 0 257 0zM383.2 338.8c11.7 11.7 11.7 30.7 0 42.4 -11.6 11.6-30.6 11.8-42.4 0L257 297.4l-85.8 83.8c-11.7 11.7-30.7 11.7-42.4 0 -11.7-11.7-11.7-30.7 0-42.4l83.8-83.8 -83.8-83.8c-11.7-11.7-11.7-30.7 0-42.4 11.7-11.7 30.7-11.7 42.4 0L257 212.6l83.8-83.8c11.7-11.7 30.7-11.7 42.4 0 11.7 11.7 11.7 30.7 0 42.4l-83.8 83.8L383.2 338.8z" />
  </svg>
);

export default CloseButton;

import { useLayoutEffect } from "react";

export const useLockScroll = (lock: boolean): void => {
  useLayoutEffect((): (() => void) => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    if (lock) {
      document.body.style.overflow = "hidden";
    }

    return (): void => {
      document.body.style.overflow = originalStyle;
    };
  }, [lock]);
};
